import { basePath } from './config';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../utils/constants';
import jwtDecode from 'jwt-decode';

export function getAccesTokenApi() {
	const accesToken = localStorage.getItem(ACCESS_TOKEN);

	if (!accesToken || accesToken === 'null') {
		return null;
	}

	return willExpiredToken(accesToken) ? null : accesToken;
}

export function getRefreshTokenApi() {
	const refreshToken = localStorage.getItem(REFRESH_TOKEN);

	if (!refreshToken || refreshToken === 'null') {
		return null;
	}

	return willExpiredToken(refreshToken) ? null : refreshToken;
}

export function refreshAccessTokenApi(refreshToken) {
	const url = `${basePath}/auth/refreshaccesstoken?token=${refreshToken}`;

	/*const bodyObj = {
		refreshToken: refreshToken,
	};*/

	const params = {
		method: 'GET',
		//body: JSON.stringify(bodyObj),
		headers: {
			'Content-Type': 'application/json',
			//Authorization: token,
		},
	};
	return (
		fetch(url, params)
			//return fetch(url, params)
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				if (result.result === 'Token Expirado') {
					logout();
					return '';
				} else {
					const { accessToken, refreshToken } = result.result;
					localStorage.setItem(ACCESS_TOKEN, accessToken);
					localStorage.setItem(REFRESH_TOKEN, refreshToken);
					return accessToken;
				}
			})
			.catch((err) => {
				return '';
			})
	);
}

export function logout() {
	localStorage.removeItem(ACCESS_TOKEN);
	localStorage.removeItem(REFRESH_TOKEN);
}

function willExpiredToken(token) {
	const seconds = 60;
	const metaToken = jwtDecode(token);

	const { exp } = metaToken;
	const now = (Date.now() + seconds) / 1000;

	return now > exp;
}

export function infoUser() {
	const token = localStorage.getItem(ACCESS_TOKEN);
	const metaToken = jwtDecode(token);

	return metaToken.data.nombre + ' ' + metaToken.data.apellido;
}

export function infoRolUser() {
	const token = localStorage.getItem(ACCESS_TOKEN);
	const metaToken = jwtDecode(token);
	return metaToken.data.rol;
}

export function infoMailUser() {
	const token = localStorage.getItem(ACCESS_TOKEN);
	const metaToken = jwtDecode(token);
	return metaToken.data.email;
}
