import { basePath } from './config';

export function signInApi(data) {
	const url = `${basePath}/auth/signin`;

	const params = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, params)
		.then((response) => {
			return response.json();
		})
		.then((result) => {
			//console.log(result);
			return result;
		})
		.catch((err) => {
			//return { ok: false, message: err.message };
			return err.message;
		});
}

export function getUsersApi(token) {
	const url = `${basePath}/user/getusers`;

	const params = {
		method: 'GET',
		//body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
	};

	return fetch(url, params)
		.then((response) => {
			return response.json();
		})
		.then((result) => {
			return result;
		})
		.catch((err) => {
			return err.message;
		});
}

export function getUsersActiveApi(token, status) {
	const url = `${basePath}/user/getusersactive?active=${status}`;

	const params = {
		method: 'GET',
		//body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
	};

	return fetch(url, params)
		.then((response) => {
			return response.json();
		})
		.then((result) => {
			return result;
		})
		.catch((err) => {
			return err.message;
		});
}

export function updateUserApi(token, user, userId) {
	const url = `${basePath}/user/upduser?id=${userId}`;

	const params = {
		method: 'POST',
		body: JSON.stringify(user),
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
	};

	return fetch(url, params)
		.then((response) => {
			return response.json();
		})
		.then((result) => {
			return result;
		})
		.catch((err) => {
			return err.message;
		});
}

export function activateUserApi(token, userId, status) {
	const url = `${basePath}/user/activateuser?id=${userId}`;

	const params = {
		method: 'POST',
		body: JSON.stringify({ active: status }),
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
	};
	return fetch(url, params)
		.then((response) => {
			return response.json();
		})
		.then((result) => {
			return result;
		})
		.catch((err) => {
			return err.message;
		});
}

export function addUserApi(token, data) {
	const url = `${basePath}/user/adduser`;

	const params = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
	};

	return fetch(url, params)
		.then((response) => {
			return response.json();
		})
		.then((result) => {
			return result;
		})
		.catch((err) => {
			return err.message;
		});
}
