import React, { useState, useEffect } from 'react';
import { getAccesTokenApi } from '../../../api/auth';
import { getUsersActiveApi } from '../../../api/user';
import Listusers from '../../../components/Admin/Users/ListUsers';

import './Users.scss';

export default function Users() {
	const [usersActive, setUsersActive] = useState([]);
	const [usersInactive, setUsersInactive] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);
	const token = getAccesTokenApi();

	useEffect(() => {
		getUsersActiveApi(token, 1).then((response) => {
			if (response.estado === 'OK') {
				setUsersActive(response.resultado);
			} else {
				setUsersActive([]);
			}
		});
		getUsersActiveApi(token, 0).then((response) => {
			if (response.estado === 'OK') {
				setUsersInactive(response.resultado);
			} else {
				setUsersInactive([]);
			}
		});
		setReloadUsers(false);
	}, [token, reloadUsers]);

	return (
		<div className="users">
			<Listusers usersActive={usersActive} usersInactive={usersInactive} setReloadUsers={setReloadUsers} />
		</div>
	);
}
