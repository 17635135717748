import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import { infoRolUser } from '../../../api/auth';

import './MenuSider.scss';

function MenuSider(props) {
	const { Sider } = Layout;
	const { menuCollpased, location } = props;
	const rol = infoRolUser();

	return (
		<Sider className="admin-sider" collapsed={menuCollpased}>
			<Menu theme="dark" mode="inline" defaultSelectedKeys={location.pathname}>
				<Menu.Item key="/cliente">
					<Link to={'/cliente'}>
						<Icon type="home" />
						<span className="nav-text">Inicio</span>
					</Link>
				</Menu.Item>
				{rol === '1' ? (
					<Menu.Item key="/admin/users">
						<Link to={'/admin/users'}>
							<Icon type="user" />
							<span className="nav-text">Usuarios</span>
						</Link>
					</Menu.Item>
				) : (
					''
				)}
				{rol === '2' ? (
					<Menu.Item key="/cliente/certificados">
						<Link to={'/cliente/certificados'}>
							<Icon type="safety-certificate" />
							<span className="nav-text">Certificados</span>
						</Link>
					</Menu.Item>
				) : (
					''
				)}
			</Menu>
		</Sider>
	);
}

export default withRouter(MenuSider);
