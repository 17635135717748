import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import useAuth from '../hooks/useAuth';

import './LayoutAdmin.scss';
import SignIn from '../pages/SignIn';
import MenuSider from '../components/Client/MenuSider';
import MenuTop from '../components/Client/MenuTop';

export default function LayoutAdmin(props) {
	const { routes } = props;
	const [menuCollpased, setMenuCollpased] = useState(false);
	const { Header, Content, Footer } = Layout;
	const { user, isLoading } = useAuth();

	if (!user && !isLoading) {
		return (
			<>
				<Route path="/" component={SignIn} />
				<Redirect to="/" />
			</>
		);
	}

	if (user && !isLoading) {
		return (
			<Layout className="layout-admin" style={{ marginLeft: menuCollpased ? '80px' : '200px' }}>
				<MenuSider menuCollpased={menuCollpased} />
				<Layout>
					<Header className="layout-admin__header">
						<MenuTop menuCollpased={menuCollpased} setMenuCollpased={setMenuCollpased} />
					</Header>
					<Content className="layout-admin__content">
						<LoadRoutes routes={routes} />
					</Content>
					<Footer className="layout-admin__footer">
						Copyright © 2018-2020{' '}
						<a href="https://www.icontec.org" target="_blank" rel="noopener noreferrer">
							ICONTEC
						</a>
						. Derechos Reservados
					</Footer>
				</Layout>
			</Layout>
		);
	}

	return null;
}

function LoadRoutes({ routes }) {
	return (
		<Switch>
			{routes.map((route, index) => (
				<Route key={index} path={route.path} exact={route.exact} component={route.component} />
			))}
		</Switch>
	);
}
