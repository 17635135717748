import React, { useState } from 'react';
import { Form, Input, Button, notification, Icon } from 'antd';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../utils/constants';

import { signInApi } from '../../api/user';

import './LoginForm.scss';

export default function LoginForm() {
	const [inputs, setInputs] = useState({ email: '', password: '' });

	const [isLoading, setIsLoading] = useState(false);

	const changeForm = (e) => {
		setInputs({
			...inputs,
			[e.target.name]: e.target.value,
		});
	};

	const login = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		if (!inputs.email || !inputs.password) {
			notification['error']({
				message: 'Debe ingresar Usuario y Contraseña',
			});
			setIsLoading(false);
		} else {
			const result = await signInApi(inputs);

			if (result.estado === 'Error') {
				notification['error']({
					message: result.result,
				});
				setIsLoading(false);
			} else {
				const { accessToken, refreshToken } = result.result;
				localStorage.setItem(ACCESS_TOKEN, accessToken);
				localStorage.setItem(REFRESH_TOKEN, refreshToken);
				notification['success']({
					message: 'LOGIN Correcto',
				});
				setIsLoading(false);
				window.location.href = '/cliente';
			}
		}
	};

	return (
		<Form className="login-form" onChange={changeForm} onSubmit={login}>
			<Form.Item>
				<Input
					prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
					type="email"
					name="email"
					placeholder="Correo Electrónico"
					className="login-form__input"
					/*onChange={inputValidation}
          value={inputs.email}*/
				/>
			</Form.Item>
			<Form.Item>
				<Input
					prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
					type="password"
					name="password"
					placeholder="Contraseña"
					className="login-form__input"
					/*onChange={inputValidation}
          value={inputs.password}*/
				/>
			</Form.Item>

			<Form.Item>
				<Button htmlType="submit" className="login-form__button" loading={isLoading}>
					Ingresar
				</Button>
			</Form.Item>
		</Form>
	);
}
