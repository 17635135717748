import React from 'react';
import { Button, Icon } from 'antd';
import Logo from '../../../assets/img/png/LOGO-head.png';
import { logout, infoUser } from '../../../api/auth';

import './MenuTop.scss';

export default function MenuTop(props) {
	const { menuCollpased, setMenuCollpased } = props;

	const logoutUser = () => {
		logout();
		window.location.reload();
	};

	return (
		<div className="menu-top">
			<div className="menu-top__left">
				<img className="menu-top__left-logo" src={Logo} alt="Juan Leon" />
				<Button type="link" onClick={() => setMenuCollpased(!menuCollpased)}>
					<Icon type={menuCollpased ? 'menu-unfold' : 'menu-fold'} />
				</Button>
			</div>
			<div className="menu-top__right">
				<span>{infoUser()}</span>
				<Button type="link" onClick={logoutUser}>
					<Icon type="poweroff" />
				</Button>
			</div>
		</div>
	);
}
