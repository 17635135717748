//Layouts
import LayoutAdmin from '../layouts/LayoutAdmin';

//Client Pages
import ClientHome from '../pages/Client';
import Certificates from '../pages/Client/Certificates';

//Pages
import SingIn from '../pages/SignIn';
import Error404 from '../pages/Error404';
import Users from '../pages/Admin/Users';

const routes = [
	{
		path: '/cliente',
		exact: false,
		component: LayoutAdmin,
		routes: [
			{
				path: '/cliente',
				component: ClientHome,
				exact: true,
			},
			{
				path: '/cliente/certificados',
				component: Certificates,
				exact: true,
			},
			{
				component: Error404,
			},
		],
	},
	{
		path: '/admin',
		exact: false,
		component: LayoutAdmin,
		routes: [
			{
				path: '/admin/users',
				component: Users,
				exact: true,
			},
			/*{
          path: "/admin/login",
          exact: true,
          component: AdminSignin,
        },*/
			{
				component: Error404,
			},
		],
	},
	{
		path: '/',
		exact: false,
		component: LayoutAdmin,
		routes: [
			{
				path: '/',
				exact: true,
				component: SingIn,
			},
			{
				component: Error404,
			},
		],
	},
];

export default routes;
