import React from 'react';
import { Pagination as PaginationAntd } from 'antd';

import './Pagination.scss';

export default function Pagination(props) {
	const { items, location, history } = props;
	const currentPage = parseInt(items.page);
	const totalPages = parseInt(items.total);
	const pagesSize = parseInt(items.limit);

	const onChangePage = (newPage) => {
		history.push(`${location.pathname}?page=${newPage}`);
	};

	return (
		<PaginationAntd
			defaultCurrent={currentPage}
			total={totalPages}
			pageSize={pagesSize}
			onChange={(newPage) => onChangePage(newPage)}
			className="pagination"
		/>
	);
}
