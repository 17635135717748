import React from 'react';
import { Layout, Tabs } from 'antd';
import { Redirect } from 'react-router-dom';
import Logo from '../assets/img/png/LOGO.png';

import LoginForm from '../components/LoginForm';

import { getAccesTokenApi } from '../api/auth';

import './SingIn.scss';

export default function SignIn() {
	const { Content } = Layout;
	const { TabPane } = Tabs;

	if (getAccesTokenApi()) {
		return <Redirect to="/cliente" />;
	}

	return (
		<Layout className="sign-in">
			<Content className="sign-in__content">
				<h1 className="sign-in__content-logo">
					<img src={Logo} alt="Huella de Confianza" />
				</h1>
				<div className="sign-in__content-tabs">
					<Tabs type="card">
						<TabPane tab={<span>Entrar</span>} key="1">
							<LoginForm />
						</TabPane>
					</Tabs>
				</div>
			</Content>
		</Layout>
	);
}
