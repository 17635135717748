import React, { useState } from 'react';
import { Form, Icon, Input, Select, Button, Row, Col, notification } from 'antd';
import { addUserApi } from '../../../../api/user';
import { getAccesTokenApi } from '../../../../api/auth';

import './AddUserForm.scss';

export default function AddUserForm(props) {
	const { setIsVisibleModal, setReloadUsers } = props;
	const [userData, setUserData] = useState({});

	const addUser = (e) => {
		e.preventDefault();
		if (
			!userData.nombre ||
			!userData.apellido ||
			!userData.email ||
			!userData.id_rol ||
			!userData.password ||
			!userData.repeatPassword
		) {
			notification['error']({
				message: 'Todos los campos son obligatorios.',
			});
		} else if (userData.password !== userData.repeatPassword) {
			notification['error']({
				message: 'Las contraseñas NO Coinciden',
			});
		} else {
			const accesToken = getAccesTokenApi();

			addUserApi(accesToken, userData)
				.then((response) => {
					if (response.estado === 'OK') {
						notification['success']({
							message: response.resultado,
						});
						setIsVisibleModal(false);
						setReloadUsers(true);
						setUserData({});
					} else {
						notification['error']({
							//message: result.resultado,
							message: 'Error Creando Usuario',
						});
					}
				})
				.catch((err) => {
					notification['error']({
						message: err,
					});
				});
		}
	};

	return (
		<div className="add-user-form">
			<AddForm userData={userData} setUserData={setUserData} addUser={addUser} />
		</div>
	);
}

function AddForm(props) {
	const { userData, setUserData, addUser } = props;
	const { Option } = Select;

	return (
		<Form className="form-add" onSubmit={addUser}>
			<Row gutter={24}>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="user" />}
							placeholder="Nombre"
							value={userData.nombre}
							onChange={(e) => setUserData({ ...userData, nombre: e.target.value })}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="user" />}
							placeholder="Apellido"
							value={userData.apellido}
							onChange={(e) => setUserData({ ...userData, apellido: e.target.value })}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="mail" />}
							placeholder="Correo Electrónico"
							value={userData.email}
							onChange={(e) => setUserData({ ...userData, email: e.target.value })}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item>
						<Select
							placeholder="Seleccione Rol"
							onChange={(e) =>
								setUserData({
									...userData,
									id_rol: e,
								})
							}
							value={userData.id_rol}
						>
							<Option value="1">Administrador</Option>
							<Option value="2">Usuario</Option>
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="lock" />}
							type="password"
							placeholder="Contraseña"
							onChange={(e) => setUserData({ ...userData, password: e.target.value })}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="lock" />}
							type="password"
							placeholder="Repetir contraseña"
							onChange={(e) => setUserData({ ...userData, repeatPassword: e.target.value })}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item>
				<Button type="primary" htmlType="submit" className="btn-submit">
					Crear Usuario
				</Button>
			</Form.Item>
		</Form>
	);
}
