import React, { useState } from 'react';
import { Switch, List, Avatar, Button, Icon, notification, Modal as ModalAntd } from 'antd';
import NoAvatar from '../../../../assets/img/png/no-avatar.png';
import Modal from '../../../../components/Modal';
import EditUserForm from '../EditUserForm/EditUserForm';
import AddUserForm from '../AddUserForm/AddUserForm';
import { activateUserApi } from '../../../../api/user';
import { getAccesTokenApi } from '../../../../api/auth';

import './ListUsers.scss';

const { confirm } = ModalAntd;

export default function Listusers(props) {
	const { usersActive, usersInactive, setReloadUsers } = props;
	const [viewUsersActive, setViewUsersActive] = useState(true);
	const [isVisibleModal, setIsVisibleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContent, setModalContent] = useState(null);

	const addUserModal = () => {
		setIsVisibleModal(true);
		setModalTitle('Nuevo Usuario');
		setModalContent(<AddUserForm setIsVisibleModal={setIsVisibleModal} setReloadUsers={setReloadUsers} />);
	};

	return (
		<div className="list-users">
			<div className="list-users__header">
				<div className="list-users__header__switch">
					<Switch defaultChecked onChange={() => setViewUsersActive(!viewUsersActive)} />
					<span>{viewUsersActive ? 'Usuarios Activos' : 'Usuarios Inactivos'}</span>
				</div>
				<Button type="primary" onClick={addUserModal}>
					Nuevo usuario
				</Button>
			</div>
			{viewUsersActive ? (
				<UsersActive
					usersActive={usersActive}
					setIsVisibleModal={setIsVisibleModal}
					setModalTitle={setModalTitle}
					setModalContent={setModalContent}
					setReloadUsers={setReloadUsers}
				/>
			) : (
				<UsersInactive usersInactive={usersInactive} setReloadUsers={setReloadUsers} />
			)}

			<Modal title={modalTitle} isVisible={isVisibleModal} setIsVisible={setIsVisibleModal}>
				{modalContent}
			</Modal>
		</div>
	);
}

function UsersActive(props) {
	const { usersActive, setIsVisibleModal, setModalTitle, setModalContent, setReloadUsers } = props;

	const editUser = (user) => {
		setIsVisibleModal(true);
		setModalTitle(`Editar ${user.nombre ? user.nombre : '...'} ${user.apellido ? user.apellido : '...'}`);
		setModalContent(
			<EditUserForm user={user} setIsVisibleModal={setIsVisibleModal} setReloadUsers={setReloadUsers} />
		);
	};

	return (
		<List
			className="users-active"
			itemLayout="horizontal"
			dataSource={usersActive}
			renderItem={(user) => <UserActive user={user} editUser={editUser} setReloadUsers={setReloadUsers} />}
		/>
	);
}

function UserActive(props) {
	const { user, editUser, setReloadUsers } = props;

	const showDesactivateConfirm = () => {
		const accessToken = getAccesTokenApi();

		confirm({
			title: 'Desactivar Usuario',
			content: `¿Está seguro de desactivar a ${user.email}?`,
			okText: 'Desactivar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk() {
				desactivateUser(accessToken);
			},
		});
	};

	const desactivateUser = (accessToken) => {
		//const accessToken = getAccesTokenApi();
		activateUserApi(accessToken, user.id, 0)
			.then((response) => {
				if (response.estado === 'OK') {
					notification['success']({
						message: response.resultado,
					});
					setReloadUsers(true);
				} else {
					notification['error']({
						message: response.resultado,
					});
				}
			})
			.catch((err) => {
				notification['error']({
					message: err,
				});
			});
	};

	return (
		<List.Item
			actions={[
				<Button type="primary" onClick={() => editUser(user)}>
					<Icon type="edit" />
				</Button>,
				<Button type="danger" onClick={showDesactivateConfirm}>
					<Icon type="stop" />
				</Button>,
				/*<Button
					type="danger"
					onClick={() => {
						console.log('eliminar');
					}}
				>
					<Icon type="delete" />
				</Button>,*/
			]}
		>
			<List.Item.Meta
				avatar={<Avatar src={user.avatar ? user.avatar : NoAvatar} />}
				title={`${user.nombre ? user.nombre : '...'} ${user.apellido ? user.apellido : '...'}`}
				description={user.email}
			/>
		</List.Item>
	);
}

function UsersInactive(props) {
	const { usersInactive, setReloadUsers } = props;

	return (
		<List
			className="users-active"
			itemLayout="horizontal"
			dataSource={usersInactive}
			renderItem={(user) => <UserInactive user={user} setReloadUsers={setReloadUsers} />}
		/>
	);
}

function UserInactive(props) {
	const { user, setReloadUsers } = props;

	const showActivateConfirm = () => {
		const accessToken = getAccesTokenApi();

		confirm({
			title: 'Activar Usuario',
			content: `¿Está seguro de activar a ${user.email}?`,
			okText: 'Activar',
			okType: 'primary',
			cancelText: 'Cancelar',
			onOk() {
				activateUser(accessToken);
			},
		});
	};

	const activateUser = (accessToken) => {
		//const accessToken = getAccesTokenApi();
		activateUserApi(accessToken, user.id, 1)
			.then((response) => {
				if (response.estado === 'OK') {
					notification['success']({
						message: response.resultado,
					});
					setReloadUsers(true);
				} else {
					notification['error']({
						message: response.resultado,
					});
				}
			})
			.catch((err) => {
				notification['error']({
					message: err,
				});
			});
	};

	return (
		<List.Item
			actions={[
				<Button type="primary" onClick={showActivateConfirm}>
					<Icon type="check" />
				</Button>,
				/*<Button
					type="danger"
					onClick={() => {
						console.log('eliminar');
					}}
				>
					<Icon type="delete" />
				</Button>,*/
			]}
		>
			<List.Item.Meta
				avatar={<Avatar src={user.avatar ? user.avatar : NoAvatar} />}
				title={`${user.nombre ? user.nombre : '...'} ${user.apellido ? user.apellido : '...'}`}
				description={user.email}
			/>
		</List.Item>
	);
}
