import React from "react";
import { List, Button, Icon, Avatar, Spin } from "antd";
import { basePathCert, basePathHuella } from "../../../../api/config";
import { ReactComponent as LinkedinIcon } from "../../../../assets/img/svg/linkedin.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/img/svg/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../../assets/img/svg/twitter.svg";

import "./CertificateList.scss";

export default function CertificateList(props) {
  const { certificates, setModalTitle, setIsVisibleModal, setModalContent } =
    props;

  return (
    <div className="certificate-list">
      <List
        dataSource={certificates.docs}
        renderItem={(certificate) => (
          <Certificate
            certificate={certificate}
            setModalTitle={setModalTitle}
            setIsVisibleModal={setIsVisibleModal}
            setModalContent={setModalContent}
          />
        )}
      ></List>
    </div>
  );
}

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

function Certificate(props) {
  const { certificate, setModalTitle, setIsVisibleModal, setModalContent } =
    props;

  var today = new Date();
  const imgTmb = `${basePathCert}/sn/${certificate.id_certificado}_tmb.png?${today}`;
  const imgCer = `${basePathCert}/${certificate.id_certificado}.png?${today}`;
  const pdfCer = `${basePathCert}/${certificate.id_certificado}.pdf?${today}`;
  const pdfCer2 = `${basePathCert}/${certificate.id_certificado}_II.pdf?${today}`;
  const pdfCerAnexo = `${basePathCert}/ANEXO_${certificate.id_certificado}.pdf?${today}`;

  const viewCertificateImage = () => {
    setModalTitle(
      `Consultar Certificado ${certificate.id_certificado_externo}`
    );
    setIsVisibleModal(true);
    //setModalContent(<img src={loading} style={{ marginLeft: '30%' }} />);
    setModalContent(<Spin spinning={true}>Cargando Imagen</Spin>);

    toDataURL(imgCer, function (dataUrl) {
      const style = {
        backgroundImage: `url(${dataUrl})`,
        width: "69vw",
        height: "90vw",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "inline-block",
      };
      setModalContent(<div style={style} />);
    });
  };

  const viewCertificatePDF = () => {
    setModalTitle(
      `Consultar PDF Certificado ${certificate.id_certificado_externo}`
    );
    setIsVisibleModal(true);
    setModalContent(
      <div id="imgConCert">
        <iframe
          id="pdfCert"
          src={pdfCer}
          style={{ width: "70vw", height: "90vw" }}
        ></iframe>
      </div>
    );
  };

  const viewCertificatePDF2 = () => {
    setModalTitle(
      `Consultar PDF plantilla 2 Certificado ${certificate.id_certificado_externo}`
    );
    setIsVisibleModal(true);
    setModalContent(
      <div id="imgConCert2">
        <iframe
          id="pdfCert2"
          src={pdfCer2}
          style={{ width: "70vw", height: "90vw" }}
        ></iframe>
      </div>
    );
  };

  const viewCertificatePDFAnexo = () => {
    setModalTitle(
      `Consultar PDF Anexo Certificado ${certificate.id_certificado_externo}`
    );
    setIsVisibleModal(true);
    setModalContent(
      <div id="imgConCertAnexo">
        <iframe
          id="pdfCertAnexo"
          src={pdfCerAnexo}
          style={{ width: "70vw", height: "90vw" }}
        ></iframe>
      </div>
    );
  };

  return (
    <List.Item
      actions={[
        certificate.genero_certificado == 1 ? (
          <Button type="primary" onClick={viewCertificateImage}>
            <Icon type="eye" />
          </Button>
        ) : (
          <Button disabled>
            <Icon type="file-pdf" />
          </Button>
        ),
        certificate.genero_certificado == 1 ? (
          <Button type="danger" onClick={viewCertificatePDF}>
            <Icon type="file-pdf" />
          </Button>
        ) : (
          <Button disabled>
            <Icon type="file-pdf" />
          </Button>
        ),
        certificate.genero_certificado_2 == 1 ? (
          <Button type="primary" onClick={viewCertificatePDF2}>
            <Icon type="file-pdf" />
          </Button>
        ) : (
          <Button disabled>
            <Icon type="file-pdf" />
          </Button>
        ),
        certificate.Total_anexo > 0 ? (
          <Button type="danger" onClick={viewCertificatePDFAnexo}>
            <Icon type="file-pdf" />
          </Button>
        ) : (
          <Button disabled>
            <Icon type="file-pdf" />
          </Button>
        ),
      ]}
      extra={<SocialShare certificate={certificate} />}
    >
      <List.Item.Meta
        title={<h2>{certificate.id_certificado_externo}</h2>}
        avatar={<Avatar src={imgTmb} />}
        description={<DetailCertificate certificate={certificate} />}
      />
    </List.Item>
  );
}

function DetailCertificate(props) {
  const { certificate } = props;

  return (
    <div>
      <h3>
        <b>{certificate.nombre}</b> {certificate.ciudad}, {certificate.pais}
      </h3>
      <span>Certificado de {certificate.proceso}</span>
      <br />
      <br />
      <div dangerouslySetInnerHTML={{ __html: certificate.alcance }} />
    </div>
  );
}

function SocialShare(props) {
  const { certificate } = props;

  const ShareFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${basePathHuella}/certificado/${certificate.id_certificado}`,
      "_blank"
    );
  };

  const ShareLinkedin = () => {
    var url =
      "https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&";

    url += `name=${certificate.proceso}-${certificate.certificado} (${certificate.id_certificado_externo})&`;
    url += "organizationId=1298608&";
    //let fecha_certificado = certificate.fecha_certificado;
    url += `issueYear=${certificate.fecha_certificado.substring(0, 4)}&`;
    url += `issueMonth=${certificate.fecha_certificado.substring(5, 7)}&`;
    url += `expirationYear=${certificate.fecha_vencimiento.substring(0, 4)}&`;
    url += `expirationMonth=${certificate.fecha_vencimiento.substring(5, 7)}&`;
    url += `certUrl=${basePathHuella}/certificado/${certificate.id_certificado}/&`;
    url += `certId=${certificate.id_certificado}`;
    var url_encoded = encodeURI(url);
    window.open(url_encoded, "_blank");
  };

  const ShareTwitter = () => {
    //window.open('https://twitter.com/intent/tweet?hashtags=HuellaDeConfianza&text=Ya+puedes+consultar+mi+certificado&url=https%3A%2F%2Fwww.huelladeconfianza.com%2Fcertificado%2F'+id+'%2F&via=icontec', '_blank');
    window.open(
      `https://twitter.com/intent/tweet?hashtags=HuellaDeConfianza&text=Ya+puedes+consultar+mi+certificado&url=${basePathHuella}/certificado/${certificate.id_certificado}/&via=icontec`,
      "_blank"
    );
  };

  return (
    <div className="social-links">
      <h3>Compártelo:</h3>
      <div className="social-links__detail">
        <a className="facebook" onClick={ShareFacebook}>
          <FacebookIcon />
        </a>
        <a className="twitter" onClick={ShareTwitter}>
          <TwitterIcon />
        </a>
        <a className="linkedin" onClick={ShareLinkedin}>
          <LinkedinIcon />
        </a>
      </div>
    </div>
  );
}
