import { basePath } from './config';

export function getCertificatesApi(limit, page, mail, token) {
	const url = `${basePath}/certificate/getalluser?mail=${mail}&limit=${limit}&page=${page}`;

	const params = {
		method: 'GET',
		//body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
	};

	return fetch(url, params)
		.then((response) => {
			return response.json();
		})
		.then((result) => {
			//console.log(result);
			return result;
		})
		.catch((err) => {
			//return { ok: false, message: err.message };
			return err.message;
		});
}
