import React, { useState, useEffect, useCallback } from 'react';
import { Avatar, Form, Icon, Input, Select, Button, Row, Col, notification } from 'antd';
import { useDropzone } from 'react-dropzone';
import NoAvatar from '../../../../assets/img/png/no-avatar.png';
import { updateUserApi } from '../../../../api/user';
import { getAccesTokenApi } from '../../../../api/auth';

import './EditUserForm.scss';

export default function EditUserForm(props) {
	const { user, setIsVisibleModal, setReloadUsers } = props;
	const [avatar, setAvatar] = useState(null);
	const [userData, setUserData] = useState({});

	useEffect(() => {
		setUserData({
			nombre: user.nombre,
			apellido: user.apellido,
			email: user.email,
			id_rol: user.id_rol,
			avatar: user.avatar,
		});
		setAvatar(user.avatar);
	}, [user]);

	useEffect(() => {
		if (avatar) {
			setUserData({
				...userData,
				avatar,
			});
		}
	}, [avatar]);

	const updateUser = (e) => {
		e.preventDefault();
		const token = getAccesTokenApi();
		let userUpdate = userData;

		if (userUpdate.password || userUpdate.repeatPassword) {
			if (userUpdate.password !== userUpdate.repeatPassword) {
				notification['error']({
					message: 'Contraseñas NO coinciden',
				});
				return;
			} else {
				delete userUpdate.repeatPassword;
			}
		}

		if (!userUpdate.nombre || !userUpdate.apellido || !userUpdate.email || !userUpdate.id_rol) {
			notification['error']({
				message: 'Nombre, Apellido, Email y Rol son obligatorios',
			});
			return;
		}

		updateUserApi(token, userUpdate, user.id).then((result) => {
			if (result.estado === 'OK') {
				notification['success']({
					message: result.resultado,
				});
				setReloadUsers(true);
				setUserData({});
				userUpdate = userData;
				setIsVisibleModal(false);
			} else {
				notification['error']({
					//message: result.resultado,
					message: 'Error actualizando Usuario',
				});
			}
		});
	};

	return (
		<div className="edit-user-form">
			<UploadAvatar avatar={avatar} setAvatar={setAvatar} />
			<EditForm setUserData={setUserData} userData={userData} updateUser={updateUser} />
		</div>
	);
}

function UploadAvatar(props) {
	const { avatar, setAvatar } = props;

	const onDrop = useCallback(
		(acceptedFiles) => {
			const file = acceptedFiles[0];
			/*img Base 64*/
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				setAvatar(event.target.result);
			};
			/*img preview url*/
			//setAvatar({ file, preview: URL.createObjectURL(file) });
		},
		[setAvatar]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: 'image/jpeg, image/png',
		noKeyboard: true,
		onDrop,
	});

	return (
		<div className="upload-avatar" {...getRootProps()}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<Avatar size={150} src={NoAvatar} />
			) : (
				/*
        //img preview
        <Avatar size={150} src={avatar ? avatar.preview : NoAvatar} />*/
				//img base 64
				<Avatar size={150} src={avatar ? avatar : NoAvatar} />
			)}
		</div>
	);
}

function EditForm(props) {
	const { userData, setUserData, updateUser } = props;

	const { Option } = Select;

	return (
		<Form className="form-edit" onSubmit={updateUser}>
			<Row gutter={24}>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="user" />}
							placeholder="Nombre"
							value={userData.nombre}
							onChange={(e) => setUserData({ ...userData, nombre: e.target.value })}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="user" />}
							placeholder="Apellido"
							value={userData.apellido}
							onChange={(e) => setUserData({ ...userData, apellido: e.target.value })}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="mail" />}
							placeholder="Correo Electrónico"
							value={userData.email}
							onChange={(e) => setUserData({ ...userData, email: e.target.value })}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item>
						<Select
							placeholder="Seleccione Rol"
							onChange={(e) =>
								setUserData({
									...userData,
									id_rol: e,
								})
							}
							value={userData.id_rol}
						>
							<Option value="1">Administrador</Option>
							<Option value="2">Usuario</Option>
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="lock" />}
							type="password"
							placeholder="Contraseña"
							onChange={(e) => setUserData({ ...userData, password: e.target.value })}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item>
						<Input
							prefix={<Icon type="lock" />}
							type="password"
							placeholder="Repetir contraseña"
							onChange={(e) => setUserData({ ...userData, repeatPassword: e.target.value })}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item>
				<Button type="primary" htmlType="submit" className="btn-submit">
					Actualizar Usuario
				</Button>
			</Form.Item>
		</Form>
	);
}
