import React, { useState, useEffect } from 'react';
import { notification, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Modal from '../../../components/Modal';
import { getCertificatesApi } from '../../../api/certificate';
import { infoMailUser, getAccesTokenApi } from '../../../api/auth';

import CertificateList from '../../../components/Client/Certificate/CertificateList/CertificateList';
import Pagination from '../../../components/Pagination/Pagination';

import './Certificates.scss';

function Certificates(props) {
	const { location, history } = props;
	const [isVisibleModal, setIsVisibleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContent, setModalContent] = useState(null);
	const [certificates, setCertificates] = useState({});
	const [reloadCertificates, setReloadCertificates] = useState(false);

	const { page = 1 } = queryString.parse(location.search);
	const itemsxpage = 10;
	const token = getAccesTokenApi();
	const email = infoMailUser();

	useEffect(() => {
		setReloadCertificates(true);
		getCertificatesApi(itemsxpage, page, email, token)
			.then((response) => {
				setReloadCertificates(false);
				if (response.estado === 'OK') {
					setCertificates(response.resultado);
				} else {
					notification['warning']({
						message: response.resultado,
					});
				}
			})
			.catch((err) => {
				setReloadCertificates(false);
				notification['error']({
					message: 'Error del Servidor',
				});
			});
	}, [page]);

	return (
		<div className="certificate">
			<h1>Certificados disponibles</h1>
			<Spin spinning={reloadCertificates}>
				<CertificateList
					certificates={certificates}
					setModalTitle={setModalTitle}
					setIsVisibleModal={setIsVisibleModal}
					setModalContent={setModalContent}
				/>
			</Spin>
			<Pagination items={certificates} location={location} history={history} />

			<Modal title={modalTitle} isVisible={isVisibleModal} setIsVisible={setIsVisibleModal} width="75%">
				{modalContent}
			</Modal>
		</div>
	);
}

export default withRouter(Certificates);
